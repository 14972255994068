.meal-category {
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #30638e;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.collapsed {
  height: 0px !important;
}

.meal-items {
  overflow-y: scroll;
  margin: 0 0 1rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 0 0 4px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  &__item {
    border-collapse: collapse;
    margin: 0.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    th {
      padding: 1rem;
      background-color: #30638e;
      color: #fff;
    }
    th:first-child {
      border-radius: 4px 0 0 0;
    }
    th:last-child {
      border-radius: 0 4px 0 0;
    }
    tr:last-child {
      border-radius: 0 0 4px 4px;
    }
    td {
      padding: 1rem;
      text-align: center;
      svg {
        width: 25px;
        height: 25px;
        color: #30638e;
        cursor: pointer;
      }
    }
    tr:nth-child(even) {
      background-color: #eee;
    }
    tr {
      transition: 0.1s ease-in-out;
    }
    tr:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) {
  .meal-items {
    grid-template-columns: repeat(3, 1fr);
  }
}
