.list-cow-meals {
  overflow-y: scroll;
  margin: 0 0 1rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  &__item {
    width: 95%;
    margin: 1rem auto;
    border: 1px solid rgba($color: #30638e, $alpha: 0.2);
    border-collapse: collapse;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    &--selected {
      border: 2px solid rgba($color: #ffa500, $alpha: 1);
      border-radius: 0.5rem;
    }
    th {
      padding: 0.2rem;
      background-color: #30638e;
      color: #fff;
    }
    th:first-child {
      border-radius: 4px 0 0 0;
    }
    th:last-child {
      border-radius: 0 4px 0 0;
    }
    tr:last-child {
      border-radius: 0 0 4px 4px;
    }
    .align-right {
      text-align: right;
    }
    td {
      padding: 0.2rem;
      svg {
        width: 25px;
        height: 25px;
        color: #30638e;
        cursor: pointer;
      }
    }
    .float-right {
      text-align: right;
    }
    tr:nth-child(even) {
      background-color: #eee;
    }
    tr {
      transition: 0.1s ease-in-out;
    }
    tr:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}
.add-meal-button {
  height: 40px !important;
  background-color: #30638e;
  color: #fff;
  text-align: center;
}
.selected-meal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0;
  padding: 0.5rem;
  color: #fff;
  background-color: #30638e;
  border-bottom: 1px solid #00000055;
  .remove-meal-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    color: #30638e;
    padding: 0.5rem;
    border-radius: 8px;
  }
  &--red {
    padding: 0.5rem;
    background-color: #ffa500;
  }
}

@media only screen and (min-width: 768px) {
  .list-cow-meals {
    grid-template-columns: repeat(2, 1fr);
  }
}
