.login {
  overflow: hidden;
  height: 100vh;
  display: flex;
  &__input-field {
    margin-bottom: 0.5rem;
  }
  &__left {
    display: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__right {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  &__heading {
    margin-bottom: 1rem;
    color: #30638e;
  }
  &__button {
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    border: 0;
    border-radius: 4px;
    background-color: #30638e;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #224563;
    }
  }
  &__under-text {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .login {
    &__left {
      display: block;
      width: 70%;
    }
    &__wrapper {
      width: 30%;
    }
  }
}
