@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './styles/Login';
@import './styles/ManageMeals';
@import './styles/ManageNutrients';
@import './styles/ManageUsers';
@import './styles/MealCategory';
@import './styles/Navigation';
@import './styles/ListCowMeals';
@import './styles/Dialog';
@import './styles/CalculationWindow';
@import './styles/MilkAnalysis';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-wrapper {
  margin: 1rem auto;
  max-width: 1440px;
  padding: 1rem 1rem 5.8rem 1rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  &__header {
    margin-bottom: 2.5rem;
  }
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}
input,
select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba($color: #30638e, $alpha: 0.2);
  border-radius: 4px;
  color: #30638e;
  background-color: #ffffff;
}

select {
  width: 100%;
}

.col-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
}

.helper-text {
  font-size: 13px;
  &--invalid {
    font-size: 13px;
    color: #d5573b;
    margin: 0 0 0 auto;
  }
}

.col-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media only screen and (min-width: 768px) {
  .col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .body-wrapper {
    padding: 1rem;
  }
}
