.nutrient-table {
  margin: 0 auto 8rem auto;
  width: 1440px;
  max-height: 80vh;
  border-collapse: collapse;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &__action-button {
    margin: 0 0 1rem 0;
    width: 150px;
    padding: 0.8rem 1.5rem;
    background-color: #30638e;
    color: #fff;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
  }
  th {
    padding: 0.5rem;
    background-color: #30638e;
    color: #fff;
  }
  th:first-child {
    border-radius: 4px 0 0 0;
  }
  th:last-child {
    border-radius: 0 4px 0 0;
  }
  tr:last-child {
    border-radius: 0 0 4px 4px;
  }
  td {
    padding: 0;
    text-align: center;
    svg {
      width: 25px;
      height: 25px;
      margin: 0 0.5rem;
      color: #30638e;
      cursor: pointer;
      &:hover {
        filter: brightness(0.5);
      }
    }
  }
  tr:nth-child(even) {
    background-color: #eee;
  }
  tr {
    transition: 0.1s ease-in-out;
  }
  tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  &-wrapper {
    max-width: 1590px;
    margin: 0 auto;
  }
  &__controls {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__tab-wrapper {
    width: 600px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
  }
  &__tab {
    padding: 0.6rem;
    border-bottom: 2px solid #30638e00;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid rgba($color: #30638e, $alpha: 0.25);
    }
    &--active {
      padding: 0.6rem;
      border: 1px solid rgba($color: #30638e, $alpha: 0.5);
      text-align: center;
      border-radius: 0.25rem;
      cursor: pointer;
      &:hover {
        border: 1px solid rgba($color: #30638e, $alpha: 0.5);
      }
    }
  }
}
