.user-table {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;
  &__action-button {
    float: right;
    margin: 0 0 1rem auto;
    padding: 0.8rem 1.5rem;
    background-color: #30638e;
    color: #fff;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
  }
  &__no-cows-message {
    display: table-cell;
    padding: 1rem;
    text-align: center;
  }
}

.table {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-collapse: collapse;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  th {
    padding: 1rem;
    background-color: #30638e;
    color: #fff;
  }
  th:first-child {
    border-radius: 4px 0 0 0;
  }
  th:last-child {
    border-radius: 0 4px 0 0;
  }
  tr:last-child {
    border-radius: 0 0 4px 4px;
  }
  td {
    padding: 1rem;
    text-align: center;
    svg {
      width: 25px;
      height: 25px;
      margin: 0 0.5rem;
      color: #30638e;
      cursor: pointer;
      &:hover {
        filter: brightness(0.5);
      }
    }
  }
  tr:nth-child(even) {
    background-color: #eee;
  }
  tr {
    transition: 0.1s ease-in-out;
  }
  tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.userdialog {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  &__header {
    padding: 1rem 0;
    text-align: center;
    background-color: #30638e;
    color: #fff;
    border-radius: 0.25rem 0.25rem 0 0;
    font-size: 1.2rem;
  }
  &__body {
    width: 500px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 700px;
  }
  &__footer {
    text-align: center;
    color: #fff;
    display: flex;

    button {
      width: 100%;
      padding: 1rem 2rem;
      border: 0;
      margin: 0 auto;
      background-color: #30638e;
      color: #fff;
      cursor: pointer;
    }
    button:hover {
      background-color: #30638ecc;
      color: #fff;
    }
    button:first-child {
      border-radius: 0 0 0 0.25rem;
    }
    button:nth-child(2) {
      border-radius: 0 0 0.25rem 0;
    }
  }
}
