.milkanalysis-window {
  &__2cols-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgba($color: #30638e, $alpha: 0.2);
    border-radius: 0.25rem;
    &-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #00000022;
    }
    &-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      justify-content: flex-start;
    }
  }

  &__input-wrapper {
    margin: 0.25rem;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.25rem;
  }
  &__button {
    margin: 1rem auto;
    padding: 0.8rem 1.5rem;
    background-color: #30638e;
    color: #fff;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
  }
  &__body-wrapper {
    max-width: 1440px;
    width: fit-content;
    margin: 1rem auto;
    padding: 1rem 1rem 5.8rem 1rem;
    margin-top: 1rem;
    border-radius: 0.25rem;
    &__header {
      margin-bottom: 2.5rem;
    }
  }
  &__table-milk {
    width: auto;
    margin: 0.5rem 0.7rem;
    font-size: 12pt;
    text-align: center;
    border-spacing: 0px;

    th {
      padding: 0.5rem;
      background-color: #30638e;
      color: #fff;
    }
    th:first-child {
      border-radius: 4px 0 0 0;
    }
    th:last-child {
      border-radius: 0 4px 0 0;
    }
    tr:last-child {
      border-radius: 0 0 4px 4px;
    }
    td {
      padding: 0;
      text-align: center;
      svg {
        width: 25px;
        height: 25px;
        margin: 0 0.5rem;
        color: #30638e;
        cursor: pointer;
        &:hover {
          filter: brightness(0.5);
        }
      }
    }
    tr:nth-child(even) {
      background-color: #eee;
    }
    tr {
      transition: 0.1s ease-in-out;
    }
    tr:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1045px) {
  .milkanalysis-window {
    &__body-wrapper {
      width: 100%;
    }
    &__2cols-wrapper {
      &-left {
        width: 30%;
        border-bottom: 0px;
        border-right: 1px solid #00000022;
      }
      &-right {
        width: 70%;
      }
    }
    &__input-wrapper {
      padding: 0.75rem;
    }
  }
}
