.mobile-navigation__wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  background-color: #30638e;
  z-index: 1;
  .mobile-navigation {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    svg {
      color: #fff;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.navigation__wrapper {
  display: none;
  width: 100%;
  padding: 1rem;
  background-color: #30638e;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  .navigation {
    &__items {
      margin-left: 2rem;
      display: flex;
      a {
        display: flex;
        align-items: center;
        margin: 0 0.25rem;
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: #fff;
        font-weight: 500;
        border-radius: 2px;
        &:hover {
          background-color: #fff;
          color: #30638e;
        }
        svg {
          margin-right: 0.5rem;
        }
      }
      button {
        display: flex;
        align-items: center;
        margin: 0 0.25rem;
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: #30638e;
        font-weight: 500;
        border-radius: 2px;
        background-color: #fff;
        border: 0;
        cursor: pointer;
        font-size: 1rem;
        font-family: 'Noto Sans', sans-serif;
        &:hover {
          background-color: #fff;
          color: #30638e !important;
        }
        svg {
          margin-left: 0.5rem;
        }
      }
    }
    &__logo {
      display: flex;
      align-items: center;
      img {
        height: 2.5rem;
      }
      h1 {
        margin-left: 0.5rem;
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}

.selectedNavItem {
  background-color: #fff;
  color: #30638e !important;
}

@media only screen and (min-width: 768px) {
  .mobile-navigation__wrapper {
    display: none;
  }
  .navigation__wrapper {
    display: block;
    .navigation {
      margin: 0 auto;
      max-width: 1440px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
