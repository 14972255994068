.calculation-window {
  &__2cols-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgba($color: #30638e, $alpha: 0.2);
    border-radius: 0.25rem;
    &-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #00000022;
    }
    &-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      justify-content: flex-start;
    }
  }

  &__input-wrapper {
    margin: 0.25rem;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.25rem;
  }
  &__result-wrapper {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__total-price {
    padding: 0.5rem;
    color: white;
    border-radius: 8px;
    background-color: #30638e;
    font-weight: 800;
  }
  &__total-percentage {
    &--invalid {
      padding: 0.5rem;
      color: white;
      border-radius: 8px;
      background-color: #d5573b;
      font-weight: 800;
    }
    &--sufficient {
      padding: 0.5rem;
      color: white;
      border-radius: 8px;
      background-color: limegreen;
      font-weight: 800;
    }
    &--insufficient {
      padding: 0.5rem;
      color: white;
      border-radius: 8px;
      background-color: orange;
      font-weight: 800;
    }
  }
}
@media only screen and (min-width: 768px) {
  .calculation-window {
    &__2cols-wrapper {
      &-left {
        width: 50% !important;
        border-bottom: 0px;
        border-right: 1px solid #00000022;
      }
      &-right {
        width: 50% !important;
      }
    }
    &__input-wrapper {
      padding: 0.75rem;
    }
  }
}
