.meal-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  &__action-button {
    float: right;
    margin: 0 0 1rem auto;
    padding: 0.8rem 1.5rem;
    background-color: #30638e;
    color: #fff;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
  }
}
