.listcowmeals {
  width: 900px !important;
}
.wrapper {
  width: 100%;
  height: 100%;
}

.dialog {
  margin: 1rem;
  width: 100%;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  &__header {
    padding: 1rem 1rem;
    border-radius: 0.25rem 0.25rem 0 0;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba($color: #30638e, $alpha: 0.2);
    color: #30638e;
  }
  &__tab-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }
  &__tab {
    padding: 1rem;
    border-bottom: 2px solid #30638e00;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-bottom: 2px solid #30638e;
    }
    &--active {
      padding: 1rem;
      border-bottom: 2px solid #30638e;
      text-align: center;
      cursor: pointer;
      &:hover {
        border-bottom: 2px solid #30638e;
      }
    }
  }
  &__body {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow-y: scroll;
    &--custom-meal {
      width: 900px;
    }
  }
  &__footer {
    display: flex;

    button {
      width: 100%;
      padding: 1rem 2rem;
      border: 0;
      margin: 0 auto;
      cursor: pointer;
      font-size: 1rem;
      color: #30638e;
      border-top: 1px solid rgba($color: #30638e, $alpha: 0.2);
      background-color: #fff;
      &:first-child {
        border-right: 1px solid rgba($color: #30638e, $alpha: 0.2);
      }
    }
    button:hover {
    }
    button:first-child {
      border-radius: 0 0 0 0.25rem;
    }
    button:nth-child(2) {
      border-radius: 0 0 0.25rem 0;
    }
  }
  .nutrient-chip-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid rgba($color: #30638e, $alpha: 0.2);
  }

  .nutrient-chip {
    display: flex;
    align-items: center;
    background-color: #ccc;
    margin: 0 0.8rem 0.4rem 0;
    padding: 0.2rem 0.4rem;
    border-radius: 16px;
    cursor: pointer;
    svg {
      margin-left: 0.25rem;
    }
  }
  input,
  select {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid rgba($color: #30638e, $alpha: 0.2);
    border-radius: 4px;
    color: #30638e;
    background-color: #ffffff;
  }
}
@media only screen and (min-width: 768px) {
  .wrapper {
    width: 100%;
  }
  .dialog {
    max-width: 500px;
  }
  .listcowmeals {
    max-width: 1440px;
    max-height: 800px;
  }
}
